.react-images__footer__caption{
  font-size: large !important;
  margin-left: 10px !important;
  text-align: center !important;
}
.css-8wgz7g{
  color: black;
}

.react-images__header_button--fullscreen{
  display: none !important;
}
.buttonLB{
  position: center;
  z-index: 100;
}
.sc-ftTHYK{
  color:white;
  opacity: 0.6;
}
img{
  border-radius: 0px;
}

.igvwig {
  border-radius: 0px;
}
.sc-bqWxrE{
  padding: 0px !important;
  border: 0px !important;
  padding-bottom: 4px !important;
}
