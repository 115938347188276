.logoImage{
    margin-left: 20px;
    width: 160px;
}
.photoID{
    width:170px;
    height:170px;
    object-fit: cover;
    border-radius: 100%;
    margin-right:20px;
}
.myHeader{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
}
.bioContainer{
    text-align: justify;
    margin: 0 40px 0 40px;
    font-size: 1.1rem;
    max-width: 1000px;
    color: white;
}

@media screen and (max-width: 970px) {
    .bioContainer{
        text-align: justify;
        margin: 0 6px 0 6px;
        font-size:0.9rem;
        max-width: 10000px;
        margin: 15px;
    }
    .photoID{
        width:130px;
        height:130px;
        margin-right:0px;
    }
    .logoImage{
        margin-left: 0px;
        width: 100px;
    }
}

hr {
    display: block;
    /* height: 1px; */
    border: 2px;
    border-top: 1px solid #ccc;
    margin: 1em 7px 1em 7px;
    padding: 0;
    border-style: solid ;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgb(199, 199, 199);
}
