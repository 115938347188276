.footerDiv{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px 3px 10px 3px;
    /* background-color: rgb(68, 68, 68);
    border-radius: 10px; */
    padding: 0 20px 0 20px;
  }
.contactButton{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    height: 30px;
    width: 150px;
    color: black;
    text-decoration: none;
    padding: 10px;
    border-style: solid ;
    border-width: 2px;
    border-radius: 5px;
    border-color: black;
    background-color: rgb(216, 216, 216);
    margin-right: 0px;
}
.contactButton:hover{
    color: rgb(233, 233, 233);
    text-decoration: none;
    padding: 10px;
    border-style: solid ;
    border-width: 2px;
    border-radius: 5px;
    border-color: black;
    background-color: rgb(48, 48, 48);
}
.copyright{
    color: rgb(255, 255, 255);
    margin-left: 0px;
}

@media screen and (max-width: 970px) {
    .copyright{
        color: rgb(255, 255, 255);
        margin-left: 5px;
        font-size: 0.9rem;
    }
    .contactButton{
        font-size: 0.9rem;
        height: 10px;
        width: 100px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
    }
    .footerDiv{
        height: 50px;
        padding: 0px;
    }
}